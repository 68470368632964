.hide-mobile {
  display: initial; }
  @media (max-width: 674px) {
    .hide-mobile {
      display: none !important; } }

.show-mobile {
  display: none; }
  @media (max-width: 673px) {
    .show-mobile {
      display: initial !important; } }

footer .partner {
  background: url(../images/footer_azoty_bg.jpg) center 0 no-repeat;
  height: 820px;
  padding-top: 50px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  text-align: center;
  color: #fff; }
  footer .partner .partner-azoty {
    float: none;
    margin: 0 auto;
    clear: both;
    margin-bottom: 25px; }
    footer .partner .partner-azoty h3 {
      font-size: 70px; }
    footer .partner .partner-azoty h4 {
      font-size: 21px; }
    footer .partner .partner-azoty p {
      font-size: 43px; }
  footer .partner p:first-child {
    font-style: italic;
    font-size: 31px;
    line-height: 1.2;
    font-family: 'Times New Roman', Arial; }
  footer .partner h3 {
    font-size: 51px;
    line-height: 1.2;
    font-weight: 700;
    position: relative;
    top: -10px; }
  footer .partner h4 {
    font-size: 15px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 22px; }
  footer .partner .logo-azoty {
    font: 0/0 Arial;
    content: transparent;
    display: inline-block;
    background: url(../images/logo-azoty.png) 0 0 no-repeat;
    width: 156px;
    height: 120px;
    visibility: hidden; }
  footer .partner .logo-PGNiG {
    font: 0/0 Arial;
    content: transparent;
    display: inline-block;
    background: url(../images/logo-PGNiG.png) 0 0 no-repeat;
    width: 114px;
    height: 88px;
    visibility: hidden; }
  footer .partner .logo-energopol {
    font: 0/0 Arial;
    content: transparent;
    display: inline-block;
    background: url(../images/logo-energopol.png) 0 0 no-repeat;
    width: 114px;
    height: 88px;
    visibility: hidden; }
  footer .partner .stick-bottom {
    width: 100%;
    text-align: center;
    position: absolute;
    bottom: 41px;
    line-height: 64px;
    height: 64px;
    font-weight: 300;
    font-size: 18px;
    text-transform: uppercase;
    margin: 0;
    color: #003057; }

footer .partners .logo-azoty,
footer .partners .logo-PGNiG,
footer .partners .logo-energopol {
  font: 0/0 Arial;
  display: inline-block;
  width: 148px;
  height: 82px; }

footer .partners .logo-azoty {
  background: url(../images/partners/mini/logo-grupa-azoty.jpg) 0 0 no-repeat; }

footer .partners .logo-PGNiG {
  background: url(../images/partners/mini/logo-pgnig.jpg) 0 0 no-repeat; }

footer .partners .logo-energopol {
  background: url(../images/partners/mini/logo-energopol.jpg) 0 0 no-repeat; }

.table-wrapper {
  position: absolute;
  overflow: hidden;
  overflow-x: auto;
  background-color: #fff;
  left: 0;
  width: 100%;
  padding-bottom: 32px; }
  .table-wrapper table {
    width: 666px !important;
    margin: 0 30px; }
    .table-wrapper table td,
    .table-wrapper table th {
      padding: 10px; }
    .table-wrapper table td {
      border-top: 1px solid #E1E1E1; }
    .table-wrapper table th {
      text-align: center; }

.youtube-cta {
  position: fixed;
  right: 0;
  top: 150px;
  z-index: 998;
  background: #fff; }
  @media (max-width: 673px) {
    .youtube-cta {
      display: none; } }
  .youtube-cta .trigger {
    width: 51px;
    height: 51px;
    border: 1px solid #E1E1E1;
    text-align: center;
    line-height: 51px; }
  .youtube-cta a {
    font-size: 49px;
    color: #D91A11; }
